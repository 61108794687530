
          @import '@/styles/globals.scss';
        























































footer {
  margin: 0 $gutter;
}

.footer-inner {
  max-width: $page-width;
  padding: $blank-line * 2 0;
  margin-right: auto;
  margin-left: auto;
  border-top: 1px solid $grey-border;
}

.footer-nav {
  // ul
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter;

  > li {
    width: 50%;
    padding-left: $gutter;
  }

  a {
    @extend %link-reset;

    &.is-current {
      cursor: default;
    }
  }
}

@media (min-width: $medium) {
  .footer-nav {
    flex-wrap: nowrap;
    justify-content: center;
    margin-left: 0;

    > li {
      width: auto;
      padding: 0 1em;
    }
  }
}
