
          @import '@/styles/globals.scss';
        


































.inner {
  display: flex;
  flex-direction: column;
}

.c-login,
.c-register {
  width: 100%;
}

.c-login {
  .login-first & {
    margin-bottom: $blank-line * 2;
  }

  .register-first & {
    order: 1;
  }
}

.c-register {
  .register-first & {
    margin-bottom: $blank-line * 2;
  }
}

/*
  @media (min-width: $medium) {

    .logreg-forms {
      @include max-width;

      > .inner {
        display: flex;
        margin-left: -$gutter;

        > * {
          padding-left: $gutter;
          width: 50%;
        }
      }
    }
  }
  */

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
