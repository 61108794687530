
          @import '@/styles/globals.scss';
        






































































































































































.contact-form {
  @include max-width(3);

  .form-inputfields {
    // @extend %fs-title;
  }

  input,
  textarea {
    // text-align: center;
  }

  textarea {
    display: block;
    overflow: hidden;
    resize: none;
  }

  .user-message {
    // margin-top: $blank-line;
    // margin-bottom: $blank-line;
  }

  button {
    // @include max-width(2);
  }
}
