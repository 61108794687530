
          @import '@/styles/globals.scss';
        




















































.c-subnav {
  // text-transform: none;
}

.subnav-list {
  padding: $blank-line / 4 0 $blank-line / 1.5;
}

@media (min-width: $large) {
  .c-subnav {
    position: absolute;
    left: 0;
    width: 100%;
    padding-top: $blank-line / 2;
    padding-left: 7.25em; // Roughly equals logo width
    margin: 0;
    pointer-events: none;
    // text-transform: uppercase;
    background-color: $white;

    &.is-current {
      pointer-events: initial;
    }
  }

  .subnav-list {
    display: flex;
    // justify-content: space-between;
    justify-content: center;
  }

  .subnav-item {
    padding: 0 1.25em;
  }
}
