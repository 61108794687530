
          @import '@/styles/globals.scss';
        




















































// .c-region-bar-item {}

.button {
  display: inline;
  width: auto;
  cursor: pointer;

  &.is-active {
    cursor: auto;
  }

  @media (min-width: $large) {
    &.is-active {
      border-bottom: 1px solid;
    }
  }
}
