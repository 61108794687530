
          @import '@/styles/globals.scss';
        






































































.c-cookie-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  padding: $gutter * 2;
  background-color: $grey-text;
}

.outer {
  max-width: $page-width;
  margin-right: auto;
  margin-left: auto;
}

.inner {
  @media (min-width: $small) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: $gutter * -2;

    > * {
      padding-left: $gutter * 2;
    }
  }
}

.body-text {
  @media (min-width: $small) {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.button {
  width: 8.5em;
}
