
          @import '@/styles/globals.scss';
        







































































































.wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.c-base-image {
  opacity: 0;
  transition: opacity 150ms ease;

  // &:global(.is-loaded) {
  &.is-loaded,
  &.lazyloaded {
    opacity: 1;
  }

  .wrapper & {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
