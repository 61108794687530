
          @import '@/styles/globals.scss';
        

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.intro-text {
  @include max-width(6);
  margin-bottom: $blank-line * 1.5;
}

.complements-families {
  // section
  // margin-top: $blank-line * -3;
}

.complements-family {
  // li
  &:not(:last-child) {
    margin-bottom: $blank-line * 2;
  }

  h3 {
    @extend %headline;

    margin: $blank-line 0;
  }
}

.family-list {
  // ul
  @include columns-fourths;
}

.family-item {
  // li
  position: relative;
  margin-bottom: $gutter;
  overflow: hidden;
  cursor: pointer;

  a {
    @extend %link-reset;
  }

  .caption {
    position: absolute;
    top: 0;
    left: 0;
    //@extend %fs-title;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba($white, 0.8);
    opacity: 0;
    transition: opacity 150ms ease;

    > .inner {
      padding-left: $gutter;
    }
  }

  &:hover {
    .caption {
      opacity: 1;
    }
  }

  .caption-price {
    margin-top: $blank-line;
  }
}

// TODO: Rename classes or modularize styles

.rtw-product {
  // section
  //margin-bottom: $blank-line;
}

.rtw--configurator {
  display: grid;
  grid-template-rows: auto auto auto;
  margin-left: -$gutter;

  > * {
    padding-left: $gutter;
  }
}

.rtw--configurator--view-left {
  width: 100%;
  margin-bottom: $blank-line;
}

.rtw--configurator--view-right {
  grid-row: 3;
  width: 100%;
  margin-bottom: $blank-line;
}

.secondary-image {
  margin-bottom: $gutter;
}

.rtw--configurator--description {
  grid-row: 2;
  width: 100%;
}

.rtw--configurator--title {
  // @extend %fs-title;
  // order: 2;
  margin-bottom: $blank-line * 2;
  // width: 100%;

  > .inner {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // margin-left: -$gutter;
    // text-align: center;

    > * {
      //flex: 0 0 auto; // TODO: needed?
      //width: auto;
      // padding-left: $gutter;
    }
  }

  a {
    @extend %link-reset;
  }

  h3 {
    @extend %headline-left-align;
  }
}

.icon-arrow-right,
.icon-arrow-left {
  cursor: pointer;
}

.rtw--configurator--body {
  @extend %body-text;
  // order: 3;
  // width: 100%;
}

.rtw--configurator--sizes {
  // order: 5;
  margin-bottom: $blank-line;
  // width: 100%;

  > .inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter;

    > * {
      flex: 0 0 auto; // TODO: needed?
      width: 16.666%;
      padding-left: $gutter;
      margin-bottom: $gutter;

      @media (min-width: $small) {
        width: 8.333%;
      }
      @media (min-width: $medium) {
        width: 16.666%;
      }
    }
  }

  label {
    text-align: center;
  }
}

.rtw--configurator--actions {
  // order: 6;
  // width: 100%;

  > .inner {
    // @include max-width(2);

    > * {
      margin-bottom: 0.75em;
    }
  }
}

.rtw--configurator--title,
.rtw--configurator--body,
.rtw--configurator--sizes,
.rtw--configurator--actions {
  // @include max-width;
}

.rtw--configurator--price,
.rtw--configurator--actions,
.rtw--configurator--details {
  margin-bottom: $blank-line * 2;
}

.rtw--configurator--details {
  @include max-width(3);

  margin-left: 0;
}

@media (min-width: $xxsmall) {
  .secondary-images {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter;
  }

  .secondary-image {
    width: 100%;
    padding-left: $gutter;

    &.is-portrait {
      width: 50%;
    }
  }
}

@media (min-width: $xsmall) {
  .rtw--configurator--actions {
    > .inner {
      display: flex;
      margin-left: -$gutter;

      > * {
        width: 50%;
        padding-left: $gutter;
      }
    }
  }
}

@media (min-width: $small) {
  .rtw--configurator--title,
  .rtw--configurator--body {
    > .inner {
      // padding-right: $gutter;
      // padding-left: $gutter;
    }
  }
}

@media (min-width: $medium) {
  .rtw--configurator {
    grid-template-rows: auto auto;
    grid-template-columns: 50% 50%;
  }

  .rtw--configurator--view-left {
    grid-row: 1 / 2;
    grid-column: 1;
    margin-bottom: $gutter;

    // &.is-only {
    //   @include max-width;
    //   width: 100%;
    //   margin-right: auto;
    //   margin-left: auto;
    // }
  }

  .rtw--configurator--view-right {
    grid-row: 2 / 3;
    grid-column: 1;
  }

  .rtw--configurator--description {
    grid-row: 1 / 3;
    grid-column: 2;
  }
}

@media (min-width: $large) {
  .rtw--configurator {
    grid-template-columns: 66.6666% 33.3333%;
  }

  .rtw--configurator--description {
    > .inner {
      padding: 0 $gutter;
    }
  }
}

@media (min-width: $xxlarge) {
  .rtw--configurator--title,
  .rtw--configurator--body,
  .rtw--configurator--sizes,
  .rtw--configurator--actions {
    // max-width: none;
    // padding-right: 25%;
    // padding-left: calc(25% + #{$gutter});
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

//
// custom belts
//
.custom--configurator {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter;

  > * {
    width: 100%;
    padding-left: $gutter;
  }
}

.custom--configurator--view-left,
.custom--configurator--view-right {
  margin-bottom: $gutter;
}

@media (min-width: $medium) {
  .custom--configurator--view-left,
  .custom--configurator--view-right {
    width: 50%;
  }
}

.custom--configurator--description {
  margin-top: $blank-line;
}

.custom--configurator--title {
  margin-bottom: $blank-line;

  a {
    @extend %link-reset;
  }

  h3 {
    @extend %headline-left-align;
  }
}

.custom--configurator--body {
  @extend %body-text;
}

.custom--configurator--param {
  // margin-bottom: $gutter;

  > .inner {
    position: relative;

    &::before {
      display: block;
      padding-top: 80%;
      content: '';
    }
  }

  button {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;

    img {
      position: relative;
      //top: 0.05em; // Optically align with button shadow
    }

    span {
      /*
        display: block;
        position: absolute;
        bottom: -1px; // Prevent white gap
        left: 0;
        padding-top: 0.3rem;
        padding-left: 1rem;
        width: 100%;
        background-color: $white;
        text-align: left;
        */
    }

    .button-label {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: center;
      background-color: rgba($white, 0.8);
      opacity: 0;
      transition: opacity 150ms ease;

      > .inner {
        //padding-left: $gutter;
      }
    }

    &:hover {
      .button-label {
        opacity: 1;
      }
    }
  }

  .param-title {
    padding-top: 0.3rem;
  }

  @media (min-width: $medium) {
    position: relative;

    .param-title {
      position: absolute;
      // bottom: 0.25rem;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-left: $gutter;
      background-color: $white;
    }

    button {
      .button-label {
        height: calc(100% - #{$blank-line});
      }
    }
  }
}

.free-form-input {
  @extend %ff-sans;
  @extend %fs-body;
  // margin-bottom: $blank-line;

  textarea {
    overflow: hidden;
    resize: none;
  }
}

.custom--configurator--actions {
  // @include max-width;
  // order: 6;
  // width: 100%;
  // margin-bottom: $blank-line * 2;

  > .inner {
    > * {
      margin-bottom: 0.75em;
    }
  }

  @media (min-width: $xsmall) {
    > .inner {
      display: flex;
      margin-left: -$gutter;

      > * {
        width: 50%;
        padding-left: $gutter;
      }
    }
  }

  @media (min-width: $medium) {
    width: 50%;
  }
}

.choices-group {
  // li
  margin-bottom: $blank-line * 2;

  h3 {
    @extend %headline;

    margin: $blank-line 0;
  }
}

.choices-list {
  // ul
  @include columns-fourths;
}

.choices-item {
  // li
  position: relative;
  margin-bottom: $gutter;
  overflow: hidden;
  cursor: pointer;

  a {
    @extend %link-reset;
  }

  .caption {
    position: absolute;
    top: 0;
    left: 0;
    //@extend %fs-title;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba($white, 0.8);
    opacity: 0;
    transition: opacity 150ms ease;

    > .inner {
      padding-left: $gutter;
    }
  }

  &:hover {
    .caption {
      opacity: 1;
    }
  }

  .caption-price {
    margin-top: $blank-line;
  }
}

.offered-option {
  @include max-width;
}

.offered-option-image {
  margin-bottom: $blank-line;
}

.offered-option-body {
  h3 {
    margin-bottom: 0.84375em; // TODO: better way?
    text-align: center;
  }

  .body-text {
    margin-bottom: $blank-line;
  }
}

.offered-option-actions {
  width: 100%;

  > .inner {
    > * {
      margin-bottom: 0.75em;
    }
  }
}

@media (min-width: $xxsmall) {
  .offered-option-actions {
    > .inner {
      display: flex;
      margin-left: -$gutter;

      > * {
        width: 50%;
        padding-left: $gutter;
      }
    }
  }
}

@media (min-width: $small) {
  .offered-option-body {
    > .inner {
      padding-right: $gutter;
      padding-left: $gutter;
    }
  }
}

.login-required,
.booking-required {
  @include max-width(4);

  margin-top: $blank-line;
  text-align: center;
}

.booking-link {
  @include max-width(2);
}

.overlay-body {
  @include max-width(4);

  margin-top: $blank-line;

  .body-text {
    margin-bottom: $blank-line;
    text-align: center;
  }
}

.overlay-actions {
  @include max-width(4);

  > .inner {
    > * {
      margin-bottom: 0.75em;
    }
  }

  @media (min-width: $xsmall) {
    > .inner {
      display: flex;
      margin-left: -$gutter;

      > * {
        flex: 1 1 50%;
        padding-left: $gutter;
      }
    }
  }
}
