// Colors
$black: rgb(0, 0, 0);
$white: rgb(255, 255, 255);
$grey-text: rgb(190, 190, 190);
$grey-border: rgb(147, 147, 147);
$grey-fill: rgb(250, 250, 250);
$grey-hover: rgb(240, 240, 240);
$red: rgb(255, 0, 0);
$dark-yellow-100: rgb(180, 134, 33);
$dark-yellow-60: rgb(210, 182, 122);
$dark-yellow-20: rgb(240, 231, 211);

// Breakpoints
$xxsmall: 20em; // 320px
$xsmall: 30em; // 480px
$small: 40em; // 640px
$medium: 48em; // 768px
//$medium: 44.6875em; // 715px
$large: 64em; // 1024px
$xlarge: 80em; // 1280px
$xxlarge: 90em; // 1440px

// Typography
// fs: font-size, lh: line-height, ls: letter-spacing
$fs-base: 0.9375rem; // 15px
$lh-base: 1.2;
$ls-headline: 0.24em;

// Spacing
$page-width: 90rem; // 1440px
$blank-line: $lh-base * 1em;
$gutter: 0.625rem; // 10px
$header-height: 2.6875rem; // 43px
$header-height-desktop: 12.25rem; // 196px
$footer-height: 5.6875rem; // 91px

$two-cols: 21.7292rem; //
$three-cols: 33.5rem; // TODO: temp value
$four-cols: 44.6875rem; // 715px
$six-cols: 66.4375rem; // 1063px

// Media queries
$mqHover: '(hover: hover) and (pointer: fine)';
