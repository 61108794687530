
          @import '@/styles/globals.scss';
        
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.custom-product {
  // section
  // margin-bottom: $blank-line;
}

.custom--configurator {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter;

  > * {
    flex: 0 0 auto; // TODO: needed?
    padding-left: $gutter;
  }
}

.custom--configurator--introduction {
  width: 100%;

  > .inner {
    @include max-width(6);
  }
}

.intro-button {
  margin-top: $blank-line;
  margin-bottom: $blank-line * 1.5;
}

.full-intro {
  padding-bottom: $blank-line;
}

.custom--configurator--param {
  margin-bottom: $gutter;

  > .inner {
    position: relative;

    &::before {
      display: block;
      padding-top: 80%;
      content: '';
    }
  }

  button {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    text-transform: uppercase;

    img {
      position: relative;
      //top: 0.05em; // Optically align with button shadow
    }

    .button-label {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: center;
      background-color: rgba($white, 0.8);
      opacity: 0;
      transition: opacity 150ms ease;

      > .inner {
        //padding-left: $gutter;
      }
    }

    &:hover {
      .button-label {
        opacity: 1;
      }
    }
  }

  .param-title {
    padding-top: 0.3rem;
  }
}

.custom--configurator--model {
  order: 1;
  width: 100%;
}

.custom--configurator--params-left,
.custom--configurator--params-right {
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
  order: 3;
  margin-left: -$gutter;

  > * {
    width: 100%;
    padding-left: $gutter;
  }
}

.custom--configurator--description {
  order: 3;
  width: 100%;
  margin-top: $blank-line;

  > .inner {
    // @include max-width;
    // padding-top: 0.5em;
  }

  h3 {
    margin-bottom: 0.84375em; // TODO: better way?
    text-align: center;
  }
}

// .product-price {
//   @extend %ff-serif;
// }

.free-form-input {
  margin-bottom: $blank-line;

  textarea {
    margin-bottom: 0;
    overflow: hidden;
    resize: none;
  }
}

.custom--configurator--actions {
  // @include max-width;
  order: 4;
  width: 100%;

  > .inner {
    > * {
      margin-bottom: 0.75em;
    }
  }
}

@media (min-width: $xxsmall) {
  .custom--configurator--params-left,
  .custom--configurator--params-right {
    > * {
      width: 50%;
    }
  }
}

@media (min-width: $xsmall) {
  .custom--configurator--actions {
    > .inner {
      display: flex;
      margin-left: -$gutter;

      > * {
        width: 50%;
        padding-left: $gutter;
      }
    }
  }
}

@media (min-width: $small) {
  .custom--configurator--body {
    > .inner {
      padding-right: $gutter;
      padding-left: $gutter;
    }
  }
}

@media (min-width: $medium) {
  .custom--configurator--model {
    order: 2;
    width: 50%;

    &:not(.is-selected) {
      > .inner {
        &::before {
          // Not sure why 2px but otherwise buttons don’t align on the bottom
          padding-top: calc(80% + 2px);
        }
      }
    }
  }

  .custom--configurator--params-left,
  .custom--configurator--params-right {
    display: block;
    flex: none;
    margin-left: 0;

    > * {
      width: auto;
      padding-left: 0;
    }

    .custom--configurator--param {
      button {
        .button-label {
          height: calc(100% - #{$blank-line});
        }
      }
    }
  }

  .custom--configurator--params-left {
    order: 1;
    width: 25%;
  }

  .custom--configurator--params-right {
    order: 3;
    width: 25%;
  }

  .custom--configurator--param {
    position: relative;

    .param-title {
      position: absolute;
      bottom: -2px; // Prevent white gap
      // left: 0;
      //padding-left: 1rem;
      width: 100%;
      background-color: $white;
    }
  }

  .custom--configurator--body {
    order: 4;

    > .inner {
      width: 50%;
    }
  }

  .custom--configurator--actions {
    width: 50%;
  }
}

.choices-group {
  // li
  margin-bottom: $blank-line * 2;

  h3 {
    @extend %headline;

    margin: $blank-line 0;
  }
}

.choices-list {
  // ul
  @include columns-fourths;
}

.choices-toe,
.choices-lining {
  .choices-list {
    @include columns-fourths($fixed-width: false);
  }
}

.choices-item {
  // li
  position: relative;
  margin-bottom: $gutter;
  overflow: hidden;
  cursor: pointer;

  a {
    @extend %link-reset;
  }

  .caption {
    position: absolute;
    top: 0;
    left: 0;
    //@extend %fs-title;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba($white, 0.8);
    opacity: 0;
    transition: opacity 150ms ease;

    > .inner {
      padding-left: $gutter;
    }
  }

  &:hover {
    .caption {
      opacity: 1;
    }
  }

  .caption-price {
    margin-top: $blank-line;
  }
}

.offered-option {
  @include max-width;
}

.offered-option-image {
  margin-bottom: $blank-line;
}

.offered-option-body {
  h3 {
    @extend %headline;

    margin-bottom: 0.84375em; // TODO: better way?
  }

  .body-text {
    margin-bottom: $blank-line;

    &.offered-selection-delivery-time {
      margin-top: -$blank-line;
    }
  }
}

.offered-option-actions {
  width: 100%;

  > .inner {
    > * {
      margin-bottom: 0.75em;
    }
  }
}

@media (min-width: $xxsmall) {
  .offered-option-actions {
    > .inner {
      display: flex;
      margin-left: -$gutter;

      > * {
        width: 50%;
        padding-left: $gutter;
      }
    }
  }
}

@media (min-width: $small) {
  .offered-option-body {
    > .inner {
      padding-right: $gutter;
      padding-left: $gutter;
    }
  }
}

.login-required,
.booking-required {
  @include max-width(4);

  margin-top: $blank-line;
  text-align: center;
}

.booking-link {
  @include max-width(2);
}

.overlay-body {
  @include max-width(4);

  margin-top: $blank-line;

  .body-text {
    margin-bottom: $blank-line;
    text-align: center;
  }
}

.overlay-actions {
  @include max-width(4);

  > .inner {
    > * {
      margin-bottom: 0.75em;
    }
  }

  @media (min-width: $xsmall) {
    > .inner {
      display: flex;
      margin-left: -$gutter;

      > * {
        flex: 1 1 50%;
        padding-left: $gutter;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
