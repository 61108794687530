
          @import '@/styles/globals.scss';
        


































.c-nav {
  // padding: $blank-line / 2 0 $blank-line;
}

// .nav-item {}

@media (min-width: $large) {
  .c-nav {
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    // padding-bottom: 3.3em;
    padding-left: 1em;
  }

  .nav-item {
    padding: 0 2em;
  }
}
