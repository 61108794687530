
          @import '@/styles/globals.scss';
        




















































// .cover-tiles {}

.tile-item {
  &:not(:last-child) {
    margin-bottom: $blank-line * 4;
  }

  &:nth-child(odd) {
    .tile-text {
      text-align: left;

      h3 {
        text-align: left;
      }
    }
    .tile-cta {
      margin-left: 0;
    }
  }

  &:nth-child(even) {
    .tile-text {
      text-align: right;

      h3 {
        text-align: right;
      }
    }
    .tile-cta {
      margin-right: 0;
    }
  }
}

.tile-text {
  padding: 0 $gutter;

  h3 {
    @extend %headline;

    margin-top: $blank-line;
    margin-bottom: $blank-line;
  }
}

.tile-cta {
  @include max-width(2);

  margin-top: $blank-line * 1.5;

  &:not(:last-child) {
    margin-bottom: $blank-line * 1.5;
  }
}

@media (min-width: $small) {
  .tile-item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: $gutter;
    }

    &:nth-child(even) {
      .tile-image {
        order: 1;
      }
    }
  }

  .tile-image {
    width: 50%;
  }

  .tile-text {
    flex: 1;
    padding: 0 $gutter * 2;
  }

  .tile-cta {
    margin-bottom: $blank-line * 1.5;
  }
}

@media (min-width: $large) {
  .tile-image {
    width: 66.6666%;
  }

  .tile-text {
    h3 {
      // margin-bottom: $blank-line * 3;
    }
  }

  .tile-cta {
    margin-top: $blank-line * 3;

    &:not(:last-child) {
      margin-bottom: $blank-line * 2.5;
    }
  }
}
