
          @import '@/styles/globals.scss';
        





















































// .c-region-bar {}

.toggle {
  display: none;

  @media (min-width: $large) {
    display: block;
    width: auto;
    margin-bottom: 0.5em;
    cursor: pointer;
  }
}

.list {
  @media (min-width: $large) {
    position: absolute;
    z-index: 1;
    display: none;

    &.is-visible {
      display: block;
    }
  }
}
