
          @import '@/styles/globals.scss';
        

























































































































































































































































































































































































































































































































































































































.intro-text {
  @include max-width(6);
  margin-bottom: $blank-line * 1.5;
}

.rtw-products-families {
  // section
  // margin-top: $blank-line * -3;
}

.rtw-products-family {
  // li
  &:not(:last-child) {
    margin-bottom: $blank-line * 2;
  }

  h3 {
    @extend %headline;

    margin: $blank-line 0;
  }
}

.family-list {
  // ul
  @include columns-fourths;
}

.family-item {
  // li
  position: relative;
  margin-bottom: $gutter;
  overflow: hidden;
  cursor: pointer;

  a {
    @extend %link-reset;
  }

  .caption {
    position: absolute;
    top: 0;
    left: 0;
    //@extend %fs-title;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba($white, 0.8);
    opacity: 0;
    transition: opacity 150ms ease;

    > .inner {
      padding-left: $gutter;
    }
  }

  &:hover {
    .caption {
      opacity: 1;
    }
  }

  .caption-price {
    margin-top: $blank-line;
  }
}

.rtw-product {
  // section
  //margin-bottom: $blank-line;
}

.rtw--configurator {
  display: grid;
  grid-template-rows: auto auto auto;
  margin-left: -$gutter;

  > * {
    padding-left: $gutter;
  }
}

.rtw--configurator--view-left {
  width: 100%;
  margin-bottom: $blank-line;
}

.rtw--configurator--view-right {
  grid-row: 3;
  // margin-bottom: $blank-line;
  width: 100%;
}

.secondary-image {
  &:not(:last-of-type) {
    margin-bottom: $gutter;
  }
}

.rtw--configurator--description {
  grid-row: 2;
  width: 100%;
}

.rtw--configurator--title {
  // @extend %fs-title;
  margin-bottom: $blank-line * 2;

  > .inner {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // margin-left: -$gutter;
    // text-align: center;

    > * {
      //flex: 0 0 auto; // TODO: needed?
      //width: auto;
      // padding-left: $gutter;
    }
  }

  a {
    @extend %link-reset;
  }

  h3 {
    @extend %headline-left-align;
  }
}

.icon-arrow-right,
.icon-arrow-left {
  cursor: pointer;
}

.rtw--configurator--details {
  // @extend %body-text;
  // order: 3;
  // width: 100%;
}

.rtw--configurator--sizes {
  // order: 5;
  margin-bottom: $blank-line;
  // width: 100%;

  > .inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter;

    > * {
      flex: 0 0 auto; // TODO: needed?
      width: 16.666%;
      padding-left: $gutter;
      margin-bottom: $gutter;

      @media (min-width: $small) {
        width: 8.333%;
      }
      @media (min-width: $medium) {
        width: 16.666%;
      }
    }
  }

  label {
    text-align: center;
  }
}

.rtw--configurator--actions {
  > .inner {
    > * {
      margin-bottom: 0.75em;
    }
  }
}

.rtw--configurator--title,
.rtw--configurator--body,
.rtw--configurator--sizes,
.rtw--configurator--actions {
  // @include max-width;
}

.rtw--configurator--price,
.rtw--configurator--actions,
.rtw--configurator--size-fit,
.rtw--configurator--details {
  margin-bottom: $blank-line * 2;
}

.rtw--configurator--size-fit,
.rtw--configurator--details {
  @include max-width(3);

  margin-left: 0;
}

.description-title {
  @extend %headline-left-align;

  margin-bottom: $blank-line;
}

.product-options {
  li {
    margin-bottom: $blank-line;
  }
}

.option-label {
  text-transform: uppercase;
}

@media (min-width: $xxsmall) {
  .secondary-images {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter;
  }

  .secondary-image {
    width: 100%;
    padding-left: $gutter;

    &.is-portrait {
      width: 50%;
    }
  }
}

@media (min-width: $xsmall) {
  .rtw--configurator--actions {
    > .inner {
      display: flex;
      margin-left: -$gutter;

      > * {
        width: 50%;
        padding-left: $gutter;
      }
    }
  }

  // .product-options {
  //   // ul
  //   margin-bottom: $blank-line;

  //   li {
  //     display: flex;
  //     margin-left: -$gutter*2;

  //     span {
  //       flex: 1;
  //       display: block;
  //       padding-left: $gutter*2;
  //     }

  //     :first-child {
  //       flex-grow: 0;
  //       min-width: 7em;
  //       white-space: nowrap;
  //     }
  //   }
  // }
}

@media (min-width: $small) {
  .rtw--configurator--title,
  .rtw--configurator--body {
    > .inner {
      // padding-right: $gutter;
      // padding-left: $gutter;
    }
  }

  .rtw--configurator--sizes {
    .body-text {
      // padding-right: $gutter;
      // padding-left: $gutter;
    }
  }
}

@media (min-width: $medium) {
  .rtw--configurator {
    grid-template-rows: auto auto;
    grid-template-columns: 50% 50%;
  }

  .rtw--configurator--view-left {
    grid-row: 1 / 2;
    grid-column: 1;
    margin-bottom: $gutter;
  }

  .rtw--configurator--view-right {
    grid-row: 2 / 3;
    grid-column: 1;
  }

  .rtw--configurator--description {
    grid-row: 1 / 3;
    grid-column: 2;
  }
}

@media (min-width: $large) {
  .rtw--configurator {
    grid-template-columns: 66.6666% 33.3333%;
  }

  .rtw--configurator--description {
    > .inner {
      padding: 0 $gutter;
    }
  }
}

@media (min-width: $xxlarge) {
  .rtw--configurator--title,
  .rtw--configurator--body,
  .rtw--configurator--sizes,
  .rtw--configurator--actions {
    // max-width: none;
    // padding-right: 25%;
    // padding-left: calc(25% + #{$gutter});
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
