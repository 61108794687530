@import '../node_modules/normalize.css/normalize.css';

@font-face {
  font-family: 'LMD Symbols';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/LMDSymbols-Regular.woff2') format('woff2'),
    url('./assets/fonts/LMDSymbols-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Basel Classic';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Basel-Classic-Regular.woff2') format('woff2'),
    url('./assets/fonts/Basel-Classic-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Basel Grotesk';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/Basel-Grotesk-Light.woff2') format('woff2'),
    url('./assets/fonts/Basel-Grotesk-Light.woff') format('woff');
}

html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%; // Prevent iOS font scaling on orientation change
}

*,
*::before,
*::after {
  box-sizing: inherit; // Use border-box for all elements
}

body {
  @extend %ff-sans;
  @extend %fs-body;

  color: $black;
  background-color: $white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  //color: inherit;
  //text-decoration: none;
}

p {
  margin-top: 0;
  margin-bottom: $blank-line;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

h1,
h2,
h3 {
  @extend %ff-title;
  @extend %fs-title;
}

h1,
h2 {
  @extend %headline;

  a {
    @extend %link-reset;
  }
}

[class^='icon-'],
[class*=' icon-'] {
  &::before {
    @extend %ff-symbols;
  }
}

.icon-arrow-right::before {
  content: '\e000';
}
.icon-arrow-left::before {
  content: '\e001';
}
.icon-close::before {
  content: '\e002';
}
.icon-nav::before {
  content: '\e003';
}
.icon-logo-short::before {
  content: '\e004';
}
.icon-logo-long::before {
  content: '\e005';
}

/*
.icon-logo {

    &:before {
        content: "\e004";
        @media (min-width: $medium) {content: "\e005";}
    }
}
*/

.body-text {
  @extend %body-text;

  a {
    @extend %text-link;
  }
}

.text-blocks {
  @include text-blocks;
}
