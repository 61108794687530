
          @import '@/styles/globals.scss';
        
































































































































.main-home {
  // padding-top: 0;
  // max-width: none;
  padding-right: 0;
  padding-left: 0;
}

// .cover {
//   // section
//   position: relative;
//   z-index: 2;
//   height: 100vh;
//   overflow: hidden;
//   margin: 0 -#{$gutter};
//   cursor: s-resize;

//   .slider {position: absolute;}
// }

// .cover-title {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 100%;
//   user-select: none;

//   h2 {
//     margin: 0;
//     transform: rotate(90deg);
//     font-size: 11.75vh;
//     line-height: 1;
//     text-shadow: 1px 1px 15px rgba($white, 0.5);
//     // Set opacity as if mix-blend-mode is not supported
//     opacity: 0.65;
//     mix-blend-mode: overlay;

//     @supports (mix-blend-mode: overlay) {
//       // !important is needed because of https://github.com/vuejs/vue-loader/issues/906
//       opacity: 1 !important;
//     }

//     &:before {
//       display: block;
//       //margin-right: -0.2em; // Otherwise logo is not perfectly centered
//     }
//   }
// }

// @media (min-width: $large) {

//   .cover-title {

//     h2 {
//       padding-bottom: 3vh; // Optically center in viewport
//       transform: rotate(0);
//       font-size: 10.5vw;
//     }
//   }
// }

// #teaser {
//   // section
//   @include max-width(6);
//   min-height: calc(100vh - #{$blank-line*6} - #{$footer-height});
//   padding-top: $header-height;
// }

// .teaser-list {
//   // ul
//   @include columns-halves;

//   a {
//     @extend %link-reset;
//   }

//   h3 {
//     @extend %fs-title;
//     @extend %headline;
//     margin-bottom: 0.65em;
//   }
// }

// .teaser-image {
//   // img
//   margin-bottom: 0.4em;
// }
