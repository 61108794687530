
          @import '@/styles/globals.scss';
        
























































.page-not-found {
  header[role='banner'],
  footer {
    display: none;
  }

  main {
    padding-top: $header-height;

    @media (min-width: $large) {
      padding-top: $header-height-desktop;
    }
  }

  .body-text {
    text-align: center;
  }
}
