
          @import '@/styles/globals.scss';
        


















// @import "~styles/globals.scss";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 250ms ease;
}

.fade-enter-active {
  transition-delay: 250ms;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
