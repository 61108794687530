
          @import '@/styles/globals.scss';
        












































































































































































































































































































.gallery-families {
  // section
}

.gallery-introduction {
  @include max-width(6);
}

.gallery-family {
  // li
  &:not(:last-child) {
    margin-bottom: $blank-line * 2;
  }

  h3 {
    @extend %headline;

    margin: $blank-line 0;
  }
}

.family-list {
  // ul
  @include columns-fourths;
}

.family-item {
  // li
  position: relative;
  margin-bottom: $gutter;
  overflow: hidden;
  cursor: pointer;

  a {
    @extend %link-reset;
  }

  .caption {
    position: absolute;
    top: 0;
    left: 0;
    //@extend %fs-title;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba($white, 0.8);
    opacity: 0;
    transition: opacity 150ms ease;

    > .inner {
      padding-left: $gutter;
    }
  }

  &:hover {
    .caption {
      opacity: 1;
    }
  }
}

.gallery--configurator {
  @include max-width;
}

.gallery--configurator--view {
  margin-bottom: $blank-line;
}

.gallery--configurator--actions {
  order: 2;
  width: 100%;
  margin-bottom: $blank-line;

  > .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: -$gutter;

    > * {
      //flex: 0 0 auto; // TODO: needed?
      //width: auto;
      padding-left: $gutter;
    }
  }

  a {
    @extend %link-reset;
  }
}

.icon-arrow-right,
.icon-arrow-left {
  @extend %fs-title;

  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
