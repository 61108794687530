
          @import '@/styles/globals.scss';
        





















































































































































.pos-logreg {
  @include max-width(3);
}

h3 {
  @extend %headline;

  margin-top: $blank-line;
  margin-bottom: $blank-line;
}

.form-inputfields {
  // @extend %fs-title;
  // margin-bottom: $blank-line;

  input,
  textarea {
    // text-align: center;
  }
}

.form-actions {
  // @include max-width(2);

  > * {
    margin-bottom: 0.75em;
  }
}

.current-user {
  margin-bottom: $blank-line * 3;

  p {
    @extend %fs-title;

    text-align: center;
  }
}

.new-user {
  margin-bottom: $blank-line * 3;
}

.user-lookup {
  @include max-width(4);
}

.user-lookup-select {
  margin-top: 1em;
  margin-bottom: 2em;
}
