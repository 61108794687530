%ff-sans {
  font-family: 'Basel Grotesk', sans-serif;
  font-weight: 300;
}

// %ff-serif {
//   font-family: "ITC Bookman W01 Light", serif;
// }

%ff-title {
  font-family: 'Basel Classic', sans-serif;
  font-weight: 400;
}

%ff-symbols {
  font-family: 'LMD Symbols';
}

%fs-body {
  font-size: $fs-base;
  line-height: $lh-base;
}

%fs-title {
  // font-size: 1.125rem;
  // line-height: 1.3333;
  font-size: 1.4em;
}

%fs-header-title {
  font-size: 1.125rem;
  line-height: 1.3333;

  @media (min-width: $large) {
    // font-size: 2rem;
    font-size: 1.7rem;
    // letter-spacing: -0.02em;
  }
}

// %fs-meta-nav {
//   font-size: 1.125rem;
//   line-height: 1.3333;
//   letter-spacing: 0;

//   @media (min-width: $large) {
//     font-size: 0.875rem; // 14px
//     line-height: 1.2857; // 18px
//   }
// }

%fs-button {
  // font-size: $fs-base;
  font-size: 1em;

  @media (min-width: $medium) {
    // Same as %fs-title
    // font-size: 1.125rem;
    // line-height: 1.3333;
  }
}

%fs-nav-button {
  font-size: 1.125rem;
  line-height: 1;
}

%headline-left-align {
  text-transform: uppercase;
  // letter-spacing: $ls-headline;
}

%headline {
  @extend %ff-title;
  // @extend %headline-left-align;

  text-align: center;
  // text-indent: $ls-headline;
}

%body-text {
  // @extend %ff-serif;
  @extend %ff-sans;
  // font-size: 0.875rem; // 14px
  // line-height: 1.2857; // 18px
  // letter-spacing: 0.035em;

  @media (min-width: $xsmall) {
    &.is-large {
      //font-size: 1.75rem; // 28px
      // font-size: 1.25rem; // 20px
      // letter-spacing: 0.005em;
    }
  }

  @media (min-width: $large) {
    &.is-large {
      // font-size: 1.5rem; // 24px
    }
  }
}

%link-reset {
  color: inherit;
  text-decoration: none;
  border-bottom: none;
}

%text-link {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
}

%border-link {
  @extend %link-reset;

  @media (min-width: $large) {
    // display: inline-block;
    line-height: inherit;

    &::after {
      display: block;
      width: 0;
      height: 1px;
      content: '';
      background-color: $black;
      transition: width 300ms ease;
    }

    &.is-current,
    &:hover {
      &::after {
        width: 100%;
      }
    }
  }
}
