
          @import '@/styles/globals.scss';
        









































































































































































































































































































































































.body-inner {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1 1 auto;
  width: 100%;
  max-width: $page-width;
  // padding: $header-height $gutter $blank-line*6;
  padding: $blank-line $gutter $blank-line * 6;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: $large) {
    // padding-top: $header-height-desktop;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
