
          @import '@/styles/globals.scss';
        
































main,
footer {
  display: none;
}
