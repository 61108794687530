
          @import '@/styles/globals.scss';
        









































































































.carousel {
  //position: relative;
}

.carousel-items {
}

.carousel-inner {
}

.carousel-item {
  //display: none;

  &.is-visible {
    //display: block;
  }

  &:not(:first-of-type) {
    //position: absolute;
    //top:0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 250ms;
  transition-property: opacity;
}

.fade-enter-active {
  transition-delay: 160ms;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

/*
  .slide-enter {transform: translateX(100vw);}
  .slide-enter-to {transform: translateX(0);}
  .slide-leave {transform: translateX(0);}
  .slide-leave-to {transform: translateX(-100vw);}
  .slide-enter-active {transition: transform 1200ms ease;}
  .slide-leave-active {transition: transform 1200ms ease;}
  */
