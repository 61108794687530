
          @import '@/styles/globals.scss';
        










































































































































































































































































































































































































































































































































section {
  h3 {
    margin-top: $blank-line;
    margin-bottom: $blank-line/2;
    text-align: center;
  }
}

.saved-products {
  border-bottom: 1px solid $grey-border;
}

.products-list {
  // ol

  h4 {
    margin-bottom: $blank-line;
  }
}

.products-item {
  margin-bottom: $blank-line * 2;
}

.product-image {
  margin-bottom: 0.4em; // TODO: temp value
}

.product-body {
}

.product-description {
  // @extend %fs-title;

  margin-bottom: $blank-line;
}

.product-meta {
  // @extend %fs-title;

  margin-bottom: $blank-line;
}

.product-actions {
  > * {
    margin-bottom: 0.75em;
  }
}

@media (min-width: $xsmall) {
  .products-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $blank-line;
    margin-left: -$gutter;

    > * {
      padding-left: $gutter;
    }

    .product-image {
      width: 100%;
    }
    .product-body,
    .product-actions {
      width: 50%;
    }

    .product-actions {
      margin-top: 0.35em;
    }
  }
}

@media (min-width: $medium) {
  .products-item {
    .product-image,
    .product-body,
    .product-actions {
      width: 33.3333%;
    }

    .product-actions {
      margin-top: 0;
    }
  }
}

@media (min-width: $large) {
  .products-item {
    .product-image,
    .product-actions {
      width: 25%;
    }

    .product-body {
      display: flex;
      flex: 1;
      margin-left: -$gutter;

      > * {
        width: 50%;
        padding-left: $gutter;
      }
    }
  }
}

.orders {
}

.orders-list {
  // @extend %fs-title;
}

.order {
  //margin-bottom: $blank-line*3;

  h4 {
    //margin-bottom: $blank-line/2;
  }
}

.order-meta {
  margin-bottom: $blank-line;
}

.order-meta-item {
  display: flex;
  //margin-left: -$gutter;

  > * {
    flex: 1 1 50%;
    //padding-left: $gutter;

    &:nth-child(even) {
      //text-align: right;
      white-space: nowrap;
    }
  }
}

//.order-pending {color: $red;}

.order-products-list {
}

.order-product {
  //margin-bottom: $blank-line/2;
}

//.order-product-status {color: $red;}

.order-product {
  margin-bottom: $blank-line * 2;
}

@media (min-width: $xsmall) {
  .order-product {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $blank-line;
    margin-left: -$gutter;

    > * {
      padding-left: $gutter;
    }

    .product-image {
      width: 100%;
    }
    .product-body,
    .product-actions {
      width: 50%;
    }

    .product-actions {
      margin-top: 0.35em;
    }
  }
}

@media (min-width: $medium) {
  .order-product {
    .product-image,
    .product-body,
    .product-actions {
      width: 33.3333%;
    }

    .product-actions {
      margin-top: 0;
    }
  }
}

@media (min-width: $large) {
  .order-product {
    .product-image,
    .product-actions {
      width: 25%;
    }

    .product-body {
      display: flex;
      flex: 1;
      margin-left: -$gutter;

      > * {
        width: 50%;
        padding-left: $gutter;
      }
    }
  }
}

.user-email {
  // @extend %fs-title;

  margin: $blank-line 0;
  text-align: center;
}

.user-logout {
  padding-bottom: $blank-line * 2;
  margin-bottom: 2em;
  border-bottom: 1px solid $grey-border;
}

.user-profile {
  padding-bottom: $blank-line * 2;
  margin-bottom: 2em;
  border-bottom: 1px solid $grey-border;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
