
          @import '@/styles/globals.scss';
        































































































































































































.products-in-cart {
  // section

  h3 {
    margin-top: $blank-line;
    margin-bottom: $blank-line/2;
    text-align: center;
  }
}

.products-list {
  // ol

  h4 {
    margin-bottom: $blank-line;
  }
}

.products-item {
  margin-bottom: $blank-line * 2;
}

.product-image {
  margin-bottom: 0.4em; // TODO: temp value

  img {
    cursor: pointer;
  }
}

.product-body {
}

.product-description {
  // @extend %fs-title;

  margin-bottom: $blank-line;
}

.product-options {
  // ol
}

.product-meta {
  // @extend %fs-title;

  margin-bottom: $blank-line;
}

.product-actions {
  > * {
    margin-bottom: 0.75em;
  }
}

@media (min-width: $xsmall) {
  .products-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $blank-line;
    margin-left: -$gutter;

    > * {
      padding-left: $gutter;
    }

    .product-image {
      width: 100%;
    }
    .product-body,
    .product-actions {
      width: 50%;
    }

    .product-actions {
      margin-top: 0.35em;
    }
  }
}

@media (min-width: $medium) {
  .products-item {
    .product-image,
    .product-body,
    .product-actions {
      width: 33.3333%;
    }

    .product-actions {
      margin-top: 0;
    }
  }
}

@media (min-width: $large) {
  .products-item {
    .product-image,
    .product-actions {
      width: 25%;
    }

    .product-body {
      display: flex;
      flex: 1;
      margin-left: -$gutter;

      > * {
        width: 50%;
        padding-left: $gutter;
      }
    }
  }
}

.cart-response {
  color: $red;
}

.is-empty-cart {
  // @extend %fs-title;

  margin-top: $blank-line;
  text-align: center;
}
