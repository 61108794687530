
          @import '@/styles/globals.scss';
        





































































































































































































.register-form {
  @include max-width(3);
}

h3 {
  @extend %headline;

  margin-top: $blank-line;
  margin-bottom: $blank-line;
}

.form-inputfields {
  // @extend %fs-title;
  // margin-bottom: $blank-line;

  input,
  textarea {
    // text-align: center;
  }
}

.form-actions {
  // @include max-width(2);

  > * {
    margin-bottom: 0.75em;
  }
}

.user-language {
  margin-bottom: $blank-line / 2;

  label {
    margin: 0 0.3em;
    text-transform: uppercase;
  }
}
