
          @import '@/styles/globals.scss';
        




















































































.logout-form {
  @include max-width(3);
}

.form-submit {
  // @include max-width(2);
}
