// Reset/normalize form elements styles
button,
input[type='submit'],
input[type='text'],
input[type='email'],
input[type='password'],
textarea,
select {
  width: 100%;
  padding: 0;
  margin: 0;
  font: inherit;
  line-height: $lh-base; // line-height:inherit isn’t working here in all cases
  color: inherit;
  background: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

// Show the overflow in Edge
button,
input {
  overflow: visible;
}

// Remove the inheritance of text transform in Firefox
button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  // Remove the inner border and padding in Firefox
  &::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  // Restore the focus styles unset by the previous rule
  &:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
}

[type='checkbox'],
[type='radio'] {
  padding: 0; // Remove the padding in IE 10-
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

// Correct the cursor style of increment and decrement buttons in Chrome
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-file-upload-button {
  -webkit-appearance: button; // Correct the inability to style clickable types in iOS and Safari
  font: inherit; // Change font properties to `inherit` in Safari
}

label {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::placeholder {
  color: $dark-yellow-60;
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  padding: 0.6em;
  margin-bottom: 1em;
  border: 1px solid $dark-yellow-100;

  &:focus {
    background-color: $dark-yellow-20;
  }
}

.button {
  display: block;
  color: $dark-yellow-100;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  transition: color 200ms ease, background-color 200ms ease;

  &.is-primary {
    padding: 0.9em 1em;
    text-transform: uppercase;
    user-select: none;
    // box-shadow: 0 0 2px 0 rgba(20,20,20,0.20), inset 0 1px 6px 1px #ececec;
    // border-radius: 6px;
    border: 1px solid $dark-yellow-100;

    &:hover,
    &:focus {
      &:not([disabled]) {
        background-color: $dark-yellow-20;
      }
    }
  }

  &[disabled] {
    cursor: default;
    opacity: 0.25;
  }

  &.is-medium {
    @extend %fs-button;

    @media (min-width: $medium) {
      padding: 0.6115em 0.8em;
    }
  }

  &.is-large {
    // @extend %fs-title;

    padding: 0.6115em 0.8em;
  }

  &.is-normal-width {
    width: auto;
  }

  &.is-centered {
    margin-right: auto;
    margin-left: auto;
  }

  &.is-negative {
    color: $white;
    background-color: $dark-yellow-100;
    opacity: 1; // Override

    &:hover,
    &:focus {
      &:not([disabled]) {
        background-color: $dark-yellow-100; // Override
      }
    }
  }

  &.is-text-link {
    @extend %text-link;
  }

  &.on-grey-background {
    background-color: transparent;
    opacity: 1; // Override

    &:hover,
    &:focus {
      &:not([disabled]) {
        color: $dark-yellow-60;
        background-color: $dark-yellow-100; // Override
      }
    }
  }

  &.inherit-color {
    color: inherit;
  }
}

select {
  // @extend %fs-button; // TODO: font-size is overwritten by defaults above

  &.is-primary {
    padding: 0.6115em 0.4em;
    border: 1px solid $dark-yellow-100;
    // text-transform: uppercase;
  }

  &.is-large {
    // @extend %fs-title;
    // padding: 0.6115em 0.8em;
  }
}

.radio {
  &.is-button {
    position: relative;
    display: block;

    input {
      position: absolute;
      z-index: -1;
      opacity: 0;

      + label {
        @extend .button, .is-primary;
        @extend .button, .is-large;

        position: relative;
        display: block;
        padding-right: 0.4em;
        padding-left: 0.4em;
        // text-align: left;
        // text-transform: none;
        cursor: pointer;
      }

      &:checked {
        + label {
          // background: #e6e6e6;
          // box-shadow: 0 0 2px 0 rgba(152,152,152,0.50), inset 0 0 3px 1px #bababa;
          color: $white;
          background-color: $dark-yellow-100;

          &:hover {
            color: $white;
            background-color: $dark-yellow-100;
          }
        }
      }

      &[disabled] {
        + label {
          cursor: default;
          opacity: 0.25;

          &:hover {
            background-color: inherit;
          }
        }
      }
    }
  }
}

.form-response {
  // @extend %fs-title;

  margin-bottom: $blank-line;
  color: $red;
  text-align: center;
}

.field-response {
  @extend %fs-body;

  margin-bottom: 0.25em;
  color: $red;
  // text-align: center;
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

fieldset[disabled] {
  input,
  textarea,
  button {
    cursor: default;
  }

  button {
    opacity: 0.25;
  }
}
