
          @import '@/styles/globals.scss';
        



















































































































































































































































































.user-profile {
  > .inner {
    @include max-width;

    text-align: center;
  }

  h3 {
    @extend %headline;

    margin-top: $blank-line;
    margin-bottom: $blank-line;
  }
}

.shipping-checkbox {
  // @extend %fs-title;

  margin-bottom: $blank-line;
  user-select: none;
}

.profile-actions {
  @include max-width(3);
}

.user-sizes {
  margin-top: $blank-line * 3;
}

.user-sizes-form {
  @include max-width;
}

.form-inputfields {
  // @extend %fs-title;

  margin-bottom: $blank-line;

  input,
  textarea {
    text-align: center;
  }
}
