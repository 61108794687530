
          @import '@/styles/globals.scss';
        





































.c-content-row {
  &:not(:last-child) {
    margin-bottom: $blank-line * 3;
  }

  @media (min-width: $small) {
    &:not(:last-child) {
      margin-bottom: $blank-line * 2;
    }
  }

  @media (min-width: $large) {
    &:not(:last-child) {
      margin-bottom: $blank-line * 3;
    }
  }
}
