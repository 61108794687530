
          @import '@/styles/globals.scss';
        









































































































































.validate-form {
  @include max-width(3);

  h3 {
    @extend %headline;

    margin-top: $blank-line;
    margin-bottom: $blank-line;
  }
}

.form-inputfields {
  // @extend %fs-title;
  // margin-bottom: $blank-line;

  input,
  textarea {
    // text-align: center;
  }
}

.form-actions {
  // @include max-width(2);

  > * {
    margin-bottom: 0.75em;
  }
}

.view-account {
  width: auto;
  // button
  margin-right: auto;
  margin-left: auto;
  color: $grey-text;
}
