
          @import '@/styles/globals.scss';
        


























































































































.booking-link {
  @include max-width(3);

  margin-bottom: $blank-line * 3;
}

.stores {
  > .inner {
    @include max-width(6);
  }
}

.store {
  margin-bottom: $blank-line * 3;

  h4 {
    @extend %ff-title;
    @extend %fs-title;
    @extend %headline-left-align;

    text-transform: none;
  }
}

.store-image {
  margin-bottom: $blank-line;
}

.store-street {
  // @extend %fs-title;

  margin-bottom: 1.8em;
}

.store-map-link {
  @extend %link-reset;
}

.store-phone,
.store-hours > li {
  display: flex;
  margin-left: -$gutter * 2;

  > * {
    padding-left: $gutter * 2;
  }
}

.store-phone {
  margin-bottom: $blank-line;
}

.store-hours {
  margin-bottom: $blank-line * 2;
  text-align: left;

  > li {
    > * {
      width: 7.25em;
    }

    :first-child {
      width: 5em;
    }
  }
}

@media (min-width: $medium) {
  .stores-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter;

    > * {
      flex: 0 0 auto;
      width: 33.3333%;
      padding-left: $gutter;
    }
  }

  .store-body {
    padding-right: $gutter;
    padding-left: $gutter;
  }
}

#contact {
  border-top: 1px solid $grey-border;

  h3 {
    @extend %headline;

    margin-top: $blank-line * 2;
    margin-bottom: $blank-line;
  }
}
