
          @import '@/styles/globals.scss';
        




















































































































































































































































































































































































































































































































































































































































































































































































































































.cart-summary {
  // @extend %fs-title;

  padding-top: $blank-line/2;
  // margin-bottom: $blank-line;
  border-top: 1px solid $grey-border;

  > .inner {
    margin-bottom: $blank-line * 4;
    margin-left: auto;
  }

  @media (min-width: $xsmall) {
    > .inner {
      width: 50%;
    }
  }

  @media (min-width: $medium) {
    > .inner {
      width: 33.333%;
    }
  }

  @media (min-width: $large) {
    > .inner {
      width: 25%;
    }
  }
}

.summary-item {
  display: flex;
  //margin-left: -$gutter;

  > * {
    flex: 1 1 50%;
    //padding-left: $gutter;

    &:nth-child(even) {
      text-align: right;
      white-space: nowrap;
    }
  }
}

.cart-actions {
  //@include max-width(2);

  > .inner {
    > * {
      margin-bottom: 0.75em;
    }
  }

  /*
    button {
      transition: opacity 250ms ease;

      &[disabled] {opacity: 0;}
    }
    */
}

.checkout-container {
  min-height: 100vh;
  padding-top: $header-height;
}

.customer-select {
  margin-top: $blank-line;
}

.selected-user {
  // @extend %fs-title;

  margin-bottom: $blank-line * 2;
  text-align: center;
}

.checkout-options {
  @include max-width;

  min-height: 100vh;
  padding-top: $header-height;

  h3 {
    @extend %headline;

    margin-top: $blank-line;
    margin-bottom: $blank-line;
  }
}

.choice-description {
  @include max-width(3);

  margin-bottom: $blank-line;
  text-align: center;
}

.shipping-summary {
  @include max-width(2);
  // @extend %fs-title;
}

.payment-options {
  margin-top: $blank-line * 4; // TODO: temp value
}

.shipping-options-list,
.payment-options-list {
  @include max-width(3);

  .radio.is-button input + label {
    text-align: left;
    text-transform: none;
  }

  > li {
    margin-bottom: 0.75em;
  }
}

.shipping-collection {
}

.store-select {
  // width: auto;
  // text-align: center;
  // text-align-last: center;
}

.checkout-actions {
  //margin-top: $blank-line*2; // TODO: temp value

  button {
    @include max-width(3);
  }
}

.action-payment-stripe {
}

.stripe-card-outer {
  max-width: 25rem;
  padding: $gutter * 1;
  //@include max-width(4);
  margin-right: auto;
  margin-bottom: $blank-line; // TODO: temp value
  margin-left: auto;
  background-color: $grey-fill;

  @media (min-width: $xsmall) {
    padding: $gutter * 9.5 $gutter * 2 $gutter * 10;
    border-radius: 1.25em;
  }
}

.stripe-card {
  padding: $gutter;
}

.card-error {
  // @extend %fs-title;

  margin: $blank-line 0;
  color: $red;
  text-align: center;
}

.pay-with-stripe {
  // button
  @include max-width(3);
}

.start-checkout {
  margin-top: $blank-line * 4; // TODO: temp value
  margin-bottom: $blank-line;
  text-align: center;

  button {
    @include max-width(3);

    transition: opacity 250ms ease;

    &[disabled] {
      opacity: 0;
    }
  }
}

.address-required {
  color: $red;
}

@media (min-width: $xsmall) {
  .cart-actions {
    @include max-width(4);

    > .inner {
      display: flex;
      margin-left: -$gutter;

      > * {
        flex: 1 1 50%;
        padding-left: $gutter;
      }
    }
  }

  .shipping-options-list,
  .payment-options-list {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: center;
    margin-bottom: $blank-line;
    // margin-left: -$gutter;

    // > li {
    //   margin-bottom: 0.75em;
    //   padding-left: $gutter;
    //   width: 50%;
    // }
  }
}

.out-of-stock {
  // @extend %fs-title;

  margin-top: $blank-line * 2;
  color: $red;
  text-align: center;

  ul {
    margin-bottom: $blank-line;
  }
}

.overlay-body {
  @include max-width(4);

  margin-top: $blank-line;

  .body-text {
    @include max-width(2);

    margin-bottom: $blank-line;
    text-align: center;
  }
}

.overlay-actions {
  @include max-width(4);

  > .inner {
    > * {
      margin-bottom: 0.75em;
    }
  }

  @media (min-width: $xsmall) {
    > .inner {
      display: flex;
      margin-left: -$gutter;

      > * {
        flex: 1 1 50%;
        padding-left: $gutter;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
}

.fade-enter-active {
  //transition-delay: .5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
