
          @import '@/styles/globals.scss';
        











































































































.c-nav-item {
  text-transform: uppercase;
  white-space: nowrap;

  a,
  button {
    @extend %border-link;
  }

  button {
    width: auto;
    text-transform: inherit;
    cursor: pointer;
  }

  @media (min-width: $large) {
    // Show subnav of hovered nav item above current subnav
    &.is-hovered {
      .subnav {
        z-index: 1;
      }
    }

    button {
      cursor: initial;
    }
  }
}
