
          @import '@/styles/globals.scss';
        


























































.body-text {
  @include max-width(4);

  margin-top: $blank-line;
  text-align: center;
}
