
          @import '@/styles/globals.scss';
        

























































.p-basic {
  @media (min-width: $xxsmall) {
    padding-right: $gutter * 2;
    padding-left: $gutter * 2;
  }
}

// .content-image {
//   margin-bottom: $blank-line;
// }

// .care {

//   .text-blocks {
//     @include text-blocks($shifted: true);
//   }
// }

// .size-guide {

//   .content-image {
//     // TODO: temp fix
//     margin-right: auto;
//     margin-left: auto;
//     max-width: 320px;
//   }
// }
