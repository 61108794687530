
          @import '@/styles/globals.scss';
        






































































.shoe-examples {
  // section
  margin-top: $blank-line * 2;
  border-top: 1px solid $grey-border;

  h3 {
    @extend %headline;

    margin: $blank-line 0;
  }
}

.shoe-examples-list {
  // ul
  @include columns-fourths;

  > li {
    margin-bottom: $gutter;

    &:nth-child(n + 7) {
      display: none;
    }

    @media (min-width: $large) {
      &:nth-child(n + 7) {
        display: list-item;
      }
      &:nth-child(n + 9) {
        display: none;
      }
    }
  }
}

.shoe-examples-item {
  // li
  position: relative;

  a {
    @extend %link-reset;
  }

  .caption {
    position: absolute;
    top: 0;
    left: 0;
    //@extend %fs-title;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba($white, 0.8);
    opacity: 0;
    transition: opacity 150ms ease;

    > .inner {
      padding-left: $gutter;
    }
  }

  &:hover {
    .caption {
      opacity: 1;
    }
  }
}
