@mixin max-width($columns: 4) {
  margin-right: auto;
  margin-left: auto;

  @if $columns == 2 {
    max-width: $two-cols;
  } @else if $columns == 3 {
    // width: 33.3333vw;
    max-width: $three-cols;
  } @else if $columns == 4 {
    max-width: $four-cols;
  } @else if $columns == 6 {
    max-width: $six-cols;
  }
}

@mixin columns-fourths($fixed-width: true) {
  @media (min-width: $xxsmall) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter;

    > * {
      flex: 0 0 auto;
      width: 50%;
      padding-left: $gutter;

      @if $fixed-width == false {
        &:nth-child(-n + 3) {
          flex-grow: 1;
        }
        &:only-child {
          flex-grow: 0;
        }
      }
    }
  }

  @media (min-width: $medium) {
    //> * {width: calc(100% / 3);} // BUG: produces rounding errors in IE (up until at least v11)
    > * {
      width: 33.3333%;
    }
  }

  // @media (min-width: $large) {
  //   > * {width: 25%;}
  // }
}

@mixin columns-halves {
  @media (min-width: $medium) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter;

    > * {
      flex: 0 0 auto;
      width: 50%;
      padding-left: $gutter;
    }
  }
}

@mixin text-blocks($shifted: false) {
  @include max-width;

  > * {
    margin-top: $blank-line;
    margin-bottom: $blank-line * 3;
  }

  h3 {
    margin-top: $blank-line;
    margin-bottom: $blank-line/2;
    text-align: center;
  }

  p {
    @extend %body-text;
  }

  a {
    @extend %text-link;
  }

  @if $shifted {
    max-width: $six-cols;

    > li {
      max-width: $four-cols;

      &:nth-child(even) {
        margin-left: auto;
      }
    }
  }
}
