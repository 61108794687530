
          @import '@/styles/globals.scss';
        











































































































































.login-required,
.booking-required {
  @include max-width(4);

  margin-top: $blank-line;
  text-align: center;
}

.overlay-body {
  @include max-width(4);

  margin-top: $blank-line;

  .body-text {
    margin-bottom: $blank-line;
    text-align: center;
  }
}

.overlay-actions {
  @include max-width(2);
}
