
          @import '@/styles/globals.scss';
        
































.item {
  &:not(:last-child) {
    margin-bottom: $gutter * 2;
  }
}
