
          @import '@/styles/globals.scss';
        



































































































.c-newsletter-signup {
  margin-top: $blank-line * 2;

  .contact-form {
    @include max-width(3);
  }

  .form-inputfields {
    display: flex;
    align-items: stretch;
    width: 100%;
  }

  input {
    flex: 1;
    margin-right: $gutter;
    margin-bottom: 0; // Override
  }

  button {
    width: auto;
  }

  .form-message {
    position: absolute;
    margin-top: $gutter / 2;
    color: $red;
  }
}
