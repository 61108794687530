
          @import '@/styles/globals.scss';
        















































































































































































































































































































































































.user-address-form {
  @include max-width(3);
}

.address-title {
  margin-bottom: $blank-line / 2;
  text-align: left;
}

.form-inputfields {
  // @extend %fs-title;

  margin-bottom: $blank-line;

  input,
  textarea {
    // text-align: center;
  }

  select {
    width: auto;
    // text-align: center;
    // text-align-last: center;
  }
}

.address-title {
  // text-align: center;

  label {
    margin: 0 0.3em;
  }
}
